import React, { useState, ReactNode } from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { Flex, Text, Heading, ButtonLink } from 'mdlkit';
import { TOO_MANY_REQUESTS } from '../../constants/errors';
import AuthCodeSessionForm from '../../components/login/AuthCodeSessionForm';
import {
  AuthCodeResponse,
  AuthCodeSession,
  ConnectionType,
} from '../../interfaces/login';
import validateAuthCodeAsync from '../../api/login/validateAuthCodeAsync';
import createAuthCodeAsync from '../../api/login/createAuthCodeAsync';
import { CallCustomerService } from '../../components/shared';
import toast from '../../utils/toast';
import getResendCodeMessage from '../../utils/resendCodeMessage';

interface Props {
  headline: string | ReactNode;
  subHeadline: string | ReactNode;
  resendCodeCta: string | ReactNode;
  authCodeRes: AuthCodeResponse;
  onResendConnection: ConnectionType;
  onSuccess: (userEmail?: string) => void;
  onResendCode: (authCode: AuthCodeResponse) => void;
  trackerResendId?: string;
  trackerVerifyId?: string;
}

const StyledFlex = styled(Flex)`
  flex-direction: column;
  position: relative;
`;

const StyledButtonLink = styled(ButtonLink)`
  margin-top: 20px;
  margin-bottom: 65px;
  z-index: 1;
`;

const AuthCodeVerificationWithoutSessionContainer = ({
  headline,
  subHeadline,
  resendCodeCta,
  authCodeRes,
  onResendConnection,
  onSuccess,
  onResendCode,
  trackerResendId,
  trackerVerifyId,
}: Props) => {
  const {
    id: authCodeId,
    user: { id },
  } = authCodeRes;
  const { formatMessage: f } = useIntl();
  const [validationError, setValidationError] = useState<boolean>(false);

  const handleVerifyAuthCode = async (
    values: AuthCodeSession
  ): Promise<any> => {
    const { userPhone, error } = await validateAuthCodeAsync(values);

    if (error) {
      const actionData = {
        controlName: `${trackerVerifyId}-attempt`,
        controlText: 'Verify',
      };
      const errorData = {
        errorLocation: 'Verify-Submit',
        errorType: 'Internal_API',
        errorCode: error.code ?? '',
        errorCause: '',
      };

      setValidationError(true);
      if (error?.payload) {
        errorData.errorCause = error.payload;
        window.createErrorEvent(actionData, errorData);
        return error.payload;
      }

      errorData.errorCause = 'Unexpected error';
      window.createErrorEvent(actionData, errorData);
      return toast.error(
        <CallCustomerService message={f({ id: 'error.unexpectedError' })} />
      );
    }
    onSuccess(userPhone);
    return undefined;
  };

  const handleResendCode = async () => {
    const { authCode, error } = await createAuthCodeAsync({
      userId: id,
      connection: onResendConnection,
    });
    if (authCode) {
      onResendCode(authCode);
      toast.success(f({ id: getResendCodeMessage(onResendConnection) }));
      return;
    }

    if (error?.code === TOO_MANY_REQUESTS) {
      toast.error(f({ id: 'login.accountRecovery.failed.tooManyRequests' }));
      return;
    }

    toast.error(
      <CallCustomerService
        message={f({ id: 'accountRecovery.resendCodeError' })}
      />
    );
  };

  return (
    <StyledFlex>
      {validationError ? (
        <Heading align="center" as="h1" mb={30}>
          <FormattedMessage id="login.invalidCode" />
        </Heading>
      ) : (
        headline
      )}
      {validationError ? (
        <Text>
          <FormattedMessage id="login.resendCodeMessage" />
        </Text>
      ) : (
        subHeadline
      )}
      <AuthCodeSessionForm
        authCodeId={authCodeId}
        userId={id}
        onSubmit={handleVerifyAuthCode}
        buttonAtEnd
      />
      <StyledButtonLink
        onClick={handleResendCode}
        data-track-event
        data-track={JSON.stringify({
          controlName: trackerResendId ?? '',
          controlText: 'Resend code',
        })}
      >
        {resendCodeCta}
      </StyledButtonLink>
    </StyledFlex>
  );
};

export default AuthCodeVerificationWithoutSessionContainer;
